<template>
  <b-container class="m-0 w-100" fluid>
    <header-slot></header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: `${currentRoute}-payment-list-clients` }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        CLIENTS
      </b-nav-item>

      <b-nav-item
        v-if="moduleId != 14 && moduleId != 10"
        :to="{ name: `${currentRoute}-payment-list-leads` }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        LEADS
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view></router-view>
    </b-card>
  </b-container>
</template>

<script>
export default {
  computed: {
    currentRoute() {
      return this.$route.meta.route;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>